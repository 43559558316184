import "./App.scss";
import Navbar from "./components/Navbar";
import rhrn1 from "./images/RHRN1.webp";
import rhrn2 from "./images/RHRN2.webp";
import rhrn3 from "./images/RHRN3.webp";
import downtown1 from "./images/Downtown1.webp";
import downtown2 from "./images/Downtown2.webp";
import downtown3 from "./images/Downtown3.webp";
import meetsmart1 from "./images/Meetsmart1.webp";
import meetsmart2 from "./images/Meetsmart2.webp";
import mbmbn from "./images/MbMbn1.webp";
import mae1 from "./images/Mae1.webp";
import mae2 from "./images/Mae2.webp";
import mae3 from "./images/Mae3.webp";
import mensroom1 from "./images/Mensroom1.webp";
import mensroom2 from "./images/Mensroom2.webp";
import mensroom3 from "./images/Mensroom3.webp";
import client1 from "./images/notableClient1.webp";
import client2 from "./images/notableClient2.webp";
import client3 from "./images/notableClient3.webp";
import client4 from "./images/notableClient4.webp";
import client5 from "./images/notableClient5.webp";
import client6 from "./images/notableClient6.webp";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    const startAnimation = (entries, observer) => {
      entries.forEach((entry) => {
        entry.target.classList.toggle("slide-in", entry.isIntersecting);
      });
    };

    const observer = new IntersectionObserver(startAnimation);
    const options = { root: null, rootMargin: "0px", threshold: 1 };

    const elements = document.querySelectorAll(".apps-section h1");
    elements.forEach((el) => {
      observer.observe(el, options);
    });
    // return () => {
    //   observer.unobserve();
    // };
  }, []);

  return (
    <>
      <Navbar />
      <div className="App">
        <section
          className="hero"
          style={{ backgroundColor: "#aff0ca", color: "white", marginTop: 140 }}
        >
          Turning your biggest, boldest ideas into Mobile Applications
        </section>
        <div
          className="text"
          style={{ margin: "70px auto 56px", fontSize: "22px" }}
        >
          We partner with you all the way from idea prototyping to UI/UX design
          and building an MVP for testing to finally building a robust, scalable
          and fully-functional app that is published on the App store/Play
          Store.
        </div>
        <div id="notable-clients">
          <h3
            style={{
              margin: "56px auto 22px",
              fontSize: "18px",
              color: "#11489C",
            }}
          >
            Notable Clients
          </h3>
          <section
            style={{
              backgroundColor: "#c5c5c5",
              height: "470px",
              padding: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "30px",
                    justifyContent: "space-between",
                  }}
                >
                  <img src={client1} alt="client_logo_1" />
                  <img src={client2} alt="client_logo_2" />
                  <img src={client3} alt="client_logo_3" />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <img
                    style={{ marginBottom: "20px" }}
                    src={client4}
                    alt="client_logo_4"
                  />
                  <img
                    style={{ marginTop: "20px" }}
                    src={client5}
                    alt="client_logo_5"
                  />
                </div>
              </div>
              <div style={{ marginTop: "100px" }}>
                <img src={client6} alt="client_logo_6" />
              </div>
            </div>
          </section>
        </div>

        <div
          className="text"
          style={{ margin: "70px auto 56px", fontSize: "22px" }}
        >
          We also explore original and creative in-house ideas applying latest
          technologies that challenge our limits and help us be ready for you.
        </div>
        <section
          className="apps-section"
          style={{
            backgroundColor: "#fdffa3",
            height: "790px",
            padding: "18px 60px ",
            marginBottom: "70px",
          }}
        >
          <h1>RHRN</h1>
          <p>
            A new-age dating app that gets matched people to meet really fast.
            Unique features include Video profiles, Instant responses, Real-time
            meetings.
            <a href="https://www.rhrn.app">https://www.rhrn.app</a>
          </p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <img src={rhrn1} alt="rhrn app demo 1" />
            <img src={rhrn2} alt="rhrn app demo 2" />
            <img src={rhrn3} alt="rhrn app demo 3" />
          </div>
        </section>
        <section
          className="apps-section"
          style={{
            backgroundColor: "#ffdddd",
            height: "807px",
            padding: "18px 60px ",
            marginBottom: "70px",
          }}
        >
          <h1>Downtown</h1>
          <p>
            A social network based on your activities and plans, and a weekend
            planner application with listing of events in your city.
            <a href="https://apps.apple.com/us/app/downtown-social-calendar/id1446272270">
              https://apps.apple.com/us/app/downtown-social-calendar/id1446272270
            </a>
          </p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <img src={downtown1} alt="downtown app demo 1" />
            <img src={downtown2} alt="downtown app demo 2" />
            <img src={downtown3} alt="downtown app demo 3" />
          </div>
        </section>
        <section
          className="apps-section"
          style={{
            backgroundColor: "#eef2ff",
            height: "685px",
            padding: "18px 60px ",
            marginBottom: "70px",
          }}
        >
          <h1>Meetsmart</h1>
          <p>
            A productivity software that makes Work Meetings more effective.
            Highlights include Smart agenda maker and time keeper, AI supported
            moderator and dashboard with productivity analyses{" "}
            <a href="https://meetsmart.in/">https://meetsmart.in/</a>
          </p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <img src={meetsmart1} alt="meetsmart app demo 1" />
            <img src={meetsmart2} alt="meetsmart app demo 2" />
          </div>
        </section>
        <section
          className="apps-section"
          style={{
            backgroundColor: "#fff9f1",
            height: "852px",
            padding: "18px 60px ",
            marginBottom: "70px",
          }}
        >
          <h1>MBMBN</h1>
          <p>
            Maybe Maybenot: a platform for debating ideas, culture and politics;
            akin to Clubhouse for debates.
            <a style={{ fontWeight: 500 }}>Work in Progress</a>
          </p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <img src={mbmbn} alt="mbmbn app demo" />
          </div>
        </section>
        <section
          className="apps-section"
          style={{
            backgroundColor: "#cbffff",
            height: "729px",
            padding: "18px 60px ",
            marginBottom: "70px",
          }}
        >
          <h1>Mae</h1>
          <p>
            Mae is a minimal to-do application that you actually want to use.
            <a style={{ fontWeight: 500 }}>Work in Progress</a>
          </p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <img src={mae1} alt="mae app demo 1" />
            <img src={mae2} alt="mae app demo 2" />
            <img src={mae3} alt="mae app demo 3" />
          </div>
        </section>
        <section
          className="apps-section"
          style={{
            backgroundColor: "#f6f6f6",
            height: "775px",
            padding: "18px 60px ",
            marginBottom: "70px",
          }}
        >
          <h1>Mensroom</h1>
          <p>
            A unique {"("}Simple, Quick and Easy{")"} men’s styling and shopping
            application. Incubated at NUMA, had 20,000 DAU at peak, featured in
            Paris Fashion Week (Category: Innovation)
            <a style={{ fontWeight: 500 }}>Closed</a>
          </p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <img src={mensroom1} alt="mensroom app demo 1" />
            <img src={mensroom2} alt="mensroom app demo 2" />
            <img src={mensroom3} alt="mensroom app demo 3" />
          </div>
        </section>
        <footer>
          <h1>
            If you have an idea for an app, contact us today at
            <a href="mailto:contact@joyage.in">contact@joyage.in</a>
          </h1>
        </footer>
      </div>
    </>
  );
}

export default App;
