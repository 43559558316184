import React from "react";
import joyageLogo from "../images/joyage-logo.png";
const Navbar = () => {
  return (
    <div>
      <header>
        <nav>
          <a className="logo" href="/">
            <img src={joyageLogo} alt="joyage logo" />
          </a>
          <div className="company-name">Joyage Online Solutions Pvt Ltd</div>
        </nav>
      </header>
    </div>
  );
};

export default Navbar;
